<template lang="pug">
div(v-if='Navegador')#navegador
  //-PC
  div.d-none.d-md-flex
    v-app-bar#navegador( app  v-if='Navegador'  color='negro'  height='100').pr-5
      router-link(@click="dirigir_a(ruta)" :to='{name: "inicio"}')
        v-img(contain :src='Navegador.logo' flat height=55 width='200').ml-8.elevation-0
      v-spacer
      v-hover(v-for='{texto, ruta, ícono, ícono_rojo}, i of Navegador.rutas' :key='i' v-slot:default="{ hover }" ).mr-5.mt-5
        .align-center(style='width: 100px!important;' ).forma_de_texto
          //-Componentes nav bar que no son entrada
          div(v-if='ruta_actual==ruta' @click="dirigir_a(ruta)").puntor
            v-img(:src='ícono_rojo' height=27 contain).mb-2.mt-3
            span( @click="dirigir_a(ruta)"   v-html='texto' :class='"primario--text"')
            .subrayado( :class='"primario"')
          div(v-else)
            div(v-if='ruta_actual=="entrada"&&ruta=="blog"||ruta_actual=="arquitectura"&&ruta=="servicios"||ruta_actual=="saneamiento"&&ruta=="servicios"||ruta_actual=="construcción"&&ruta=="servicios"')
              //-Cuando es entrada
              div(v-if='ruta_actual=="entrada"&&ruta=="blog"' @click="dirigir_a(ruta)").puntor
                v-img(:src='ícono_rojo' height=27 contain).mb-2.mt-3
                span( @click="dirigir_a(ruta)"   v-html='"BLOG"' :class='"primario--text"')
                .subrayado( :class='"primario"')
              //-Arquitectura
              div(v-if='ruta_actual=="arquitectura"&&ruta=="servicios"').puntor
                v-menu(bottom offset-y)
                  template(v-slot:activator='{on, attrs}')
                    div
                      v-img(v-on='on' v-bind='attrs' :src='ícono_rojo' height=27 contain).mb-2.mt-3
                      span(v-on='on' v-html='"SERVICIOS"' :class='"primario--text"')
                      .subrayado( :class='"primario"')  
                  v-list(color='primario')
                    v-list-item(v-for="{texto, ruta}, i in servicios" :key="i" @click="dirigir_a(ruta)" )
                      v-list-item-content
                        v-list-item-title(v-html='texto').white--text
                          
              //-Saneamiento
              div(v-if='ruta_actual=="saneamiento"&&ruta=="servicios"').puntor
                v-menu(bottom offset-y)
                  template(v-slot:activator='{on, attrs}')
                    div
                      v-img(v-on='on' v-bind='attrs' :src='ícono_rojo' height=27 contain).mb-2.mt-3
                      span(v-on='on' v-html='"SERVICIOS"' :class='"primario--text"')
                      .subrayado( :class='"primario"')  
                  v-list(color='primario')
                    v-list-item(v-for="{texto, ruta}, i in servicios" :key="i" @click="dirigir_a(ruta)" )
                      v-list-item-content
                        v-list-item-title(v-html='texto').white--text
              //-Construcción
              div(v-if='ruta_actual=="construcción"&&ruta=="servicios"').puntor
                v-menu(bottom offset-y)
                  template(v-slot:activator='{on, attrs}')
                    div
                      v-img(v-on='on' v-bind='attrs' :src='ícono_rojo' height=27 contain).mb-2.mt-3
                      span(v-on='on' v-html='"SERVICIOS"' :class='"primario--text"')
                      .subrayado( :class='"primario"')  
                  v-list(color='primario')
                    v-list-item(v-for="{texto, ruta}, i in servicios" :key="i" @click="dirigir_a(ruta)" )
                      v-list-item-content
                        v-list-item-title(v-html='texto').white--text
            //-Cuando no es entrada
            div(v-else).puntor
              //-Cuando no sea servicios
              div(v-if='ruta!="servicios"'  @click="dirigir_a(ruta)")
                v-img(:src='hover ? ícono_rojo: ícono' height=27 contain).mb-2.mt-3
                span( @click="dirigir_a(ruta)"   v-html='texto' :class='hover? "primario--text": "white--text"')
                .subrayado( :class='!hover ? "transparent" : "primario"') 
              //-Cuando sea servicios
              div(v-else)
                v-menu(bottom offset-y)
                  template(v-slot:activator='{on, attrs}')
                    div
                      v-img(v-on='on' v-bind='attrs' :src='hover ? ícono_rojo: ícono' height=27 contain).mb-2.mt-3
                      span(v-on='on' v-html='texto' :class='hover? "primario--text": "white--text"')
                      .subrayado( :class='!hover ? "transparent" : "primario"')
                  v-list(color='primario')
                    v-list-item(v-for="{texto, ruta}, i in servicios" :key="i" @click="dirigir_a(ruta)" )
                      v-list-item-content
                        v-list-item-title(v-html='texto').white--text
                  
  //-Móvil
  div.d-md-none
    v-app-bar#navegador( app   v-if='Navegador'  color='negro'   height='100' ).pr-5
      router-link(:to='{name: "inicio"}')
        v-img(contain :src='Navegador.logo' flat height=85 width=160).elevation-0
      v-spacer
      v-btn(icon  @click='drawer = !drawer' dark)
        v-icon(color='white' x-large) fas fa-bars
    v-navigation-drawer( temporary v-model="drawer" app).negro
      v-list.negro
        v-list-item
          router-link(:to='{name: "inicio"}')
            v-img( height=85 width=160 :src='Navegador.logo' contain).justify-center
        v-list-item(@click='ruta_actual!="inicio" ? dirigir_a("inicio") : drawer=false')
          v-list-item-content
              v-list-item-title.white--text INICIO
        v-list-item(v-if='ruta!="servicios"' v-for='{texto, ruta}, i of Navegador.rutas' :key='i' 
          @click='ruta_actual!=ruta ? dirigir_a(ruta) : drawer=false').my-3
          v-list-item-content
              v-list-item-title(v-html='texto').white--text
        v-list-item(v-else).my-3
          v-menu(bottom offset-y)
            template(v-slot:activator='{on, attrs}')
              v-list-item-content(v-on='on' v-bind='attrs')
                v-container(fluid)
                  v-row
                    v-col.ma-0.pa-0
                      v-list-item-title(v-html='texto').white--text
                    v-col.ma-0.pa-0  
                      v-icon(v-on='on' x-small size=15) 
            v-list(color='primario')
              v-list-item(v-for="{texto, ruta}, i in servicios" :key="i" 
              @click='ruta_actual!=ruta ? dirigir_a(ruta) : drawer=false' )
                v-list-item-icon
                  v-icon fas fa-angle-right
                v-list-item-content
                  v-list-item-title(v-html='texto.toUpperCase()').white--text
                
</template>
<script>
export default {
  data: ()=>({
    drawer: false,
    servicios: [
      {texto: 'Construcción', ruta: 'construcción'},
      {texto: 'Saneamiento físico legal', ruta: 'saneamiento'},
      {texto: 'Arquitectura', ruta: 'arquitectura'},
    ],
  }),
  computed: {
      ruta_actual(){
          return this.$route.name
      },
  },
  methods: {
    dirigir_a(ruta){
      if(ruta!="servicios") this.$router.replace({name: ruta, params: {}})
      else this.$router.replace({name: "construcción", params: {}})
      this.$vuetify.goTo("#navegador")
    },
  },
}
</script>
<style lang="sass">
    .forma_de_texto
      text-align: center!important
    .subrayado
      height: 10px!important
      width: 100px!important
      transition: .5s all ease
    .puntor
      cursor: pointer
</style>